import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { messagingIntegrationsGuardLogic } from '@core/guards/messaging-integrations-logic';
import { MessagingIntegrationsService } from '@core/services/third-party-integrations/messaging-integrations.service';
import { APP_SLUGS, MAIN_SLUGS } from '@routes/routes.types';
import { INBOX_SLUGS } from '../inbox.routes';

export const inbPostsGuard: CanActivateFn = (route, state) => {
    const integrationsS = inject(MessagingIntegrationsService);

    const redirectUrl = `${APP_SLUGS.MAIN}/${MAIN_SLUGS.SMART_INBOX}/${INBOX_SLUGS.goToConnect}`;

    return messagingIntegrationsGuardLogic(
        integrationsS.isAnyPostsChannelConnected,
        state.url,
        redirectUrl
    );
};

import { Route } from '@angular/router';
import { subscriptionGuard } from '@features/settings/billing/guards/subscription.guard';
import { MAIN_SLUGS } from '@routes/routes.types';
import { inbConvosGuard } from './inb-convos/inb-convos.guard';
import { inbGoToConnectGuard } from './inb-go-to-connect/inb-go-to-connect.guard';
import { inbPostsGuard } from './inb-posts/inb-posts.guard';

export const INBOX_SLUGS = {
    conversations: 'conversations',
    comments: 'comments',
    goToConnect: 'go-to-connect',
} as const;

export const INBOX_ROUTE: Route = {
    path: MAIN_SLUGS.SMART_INBOX,
    title: 'Inbox',
    canActivate: [subscriptionGuard],
    loadComponent: () => import('@features/inbox/inbox.component').then(m => m.InboxComponent),
    children: [
        {
            path: INBOX_SLUGS.conversations,
            title: 'Conversations',
            canActivate: [inbConvosGuard],
            loadComponent: () =>
                import('@features/inbox/inb-convos/inb-convos.component').then(
                    m => m.InbConvosComponent
                ),
        },
        {
            path: INBOX_SLUGS.comments,
            title: 'Comments',
            canActivate: [inbPostsGuard],
            loadComponent: () =>
                import('@features/inbox/inb-posts/inb-posts.component').then(
                    m => m.InbPostsComponent
                ),
        },
        {
            path: INBOX_SLUGS.goToConnect,
            canActivate: [inbGoToConnectGuard],
            title: 'Go to connect',
            loadComponent: () =>
                import('@features/inbox/inb-go-to-connect/inb-go-to-connect.component').then(
                    m => m.InbGoToConnectComponent
                ),
        },
        { path: '', pathMatch: 'full', redirectTo: INBOX_SLUGS.conversations },
        { path: '**', redirectTo: INBOX_SLUGS.conversations },
    ],
};
